<template>
  <header class="bg-white header-shadow">
    <div class="site-container-lg py-3 px-4 md:flex md:justify-center">
      <img class='-425:w-[170px] -425:h-[39px]' src="@/assets/img/tm_logo.svg" alt="texnomart-logo" />
    </div>
  </header>
</template>

<script>
export default {};
</script>

<style></style>
