import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
import Comment from "../views/order/comment.vue";
import Error from "../views/error.vue";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  // },
  {
    path: `/order/comment`,
    name: "comment",
    component: Comment,
  },
  // {
  //   path: "*",
  //   component: Error,
  // },
];

const router = new VueRouter({
  routes,
});

export default router;
