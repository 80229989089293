<template>
  <div id="app" class="bg-gray-5 h-screen">
    <Header />
    <div class="flex items-center justify-center mt-[70px] mb-[70px] md:mt-12">
      <!-- page 1 -->

      <div
        class="site-container-sm rounded-2xl box-shadow md:relative"
        v-if="showPage"
      >
        <div
          class="bg-gray-1 text-white text-center text-lg py-5 px-5 rounded-t-2xl md:rounded-none"
        >
          Оцените пожалуйста качество обслуживания
        </div>
        <div
          class="bg-white py-[50px] rounded-b-2xl px-6 md:pt-[40px] pb-[80px]"
        >
          <div class="text-center mb-9">
            Выберите оценку 1 - 10, насколько вам было удобно оформлять заказ.
            <br />
            Оставьте ваш комментарий с пожеланиями для нас.
          </div>
          <div class="max-w-[620px] m-auto mb-9">
            <div
              v-if="marks?.length"
              class="flex justify-between md:flex-wrap md:justify-center md:mx-[-24px]"
            >
              <button
                v-for="item in marks"
                :key="item.id"
                class="button-round"
                @click="changeTab(item.id)"
                :class="form.mark === item.id ? 'active' : ''"
              >
                {{ item.name }}
              </button>
            </div>
            <div class="error mt-1.5 text-danger-1" v-if="markError">
              Вы не выбрали оценку
            </div>
          </div>
          <div v-if="errorMessage">Выберите один из вариантов</div>
          <div
            class="mb-[92px] max-w-[620px] m-auto relative form-input-wrap md:mb-0"
          >
            <input
              type="text"
              placeholder=" "
              class="form-input"
              v-model="form.comment"
              :class="commentError ? 'input-error' : ''"
              @keyup="changeComment()"
            />
            <span class="form-text text-base">Введите ваш комментарий</span>
            <div class="form-error text-danger-1 mt-1.5" v-if="commentError">
              Обязательное поле
            </div>
          </div>
          <button
            class="bg-golden flex items-center justify-center w-[220px] h-[50px] m-auto rounded-[8px] text-base font-medium md:absolute md:bottom-[-24px] md:left-[50%] md:translate-x-[-50%]"
            @click="sendСhoice()"
          >
            <span>Далее</span>
            <img
              src="@/assets/img/icon-right.svg"
              alt="icon-right"
              class="ml-2"
            />
          </button>
        </div>
      </div>

      <!-- page 2 -->
      <div
        class="max-w-[540px] w-full mx-6 rounded-2xl box-shadow bg-white px-[70px] py-[50px] text-center md:px-[20px]"
        v-else
      >
        <img src="@/assets/img/success.svg" alt="success" class="mb-5 m-auto" />
        <div class="text-lg font-medium mb-2">Спасибо за вашу оценку</div>
        <div class="mb-10">Мы стараемся быть лучше для вас</div>

        <div
          @click="finishNps"
          class="w-32 h-11 bg-golden rounded-lg flex items-center justify-center text-base font-medium m-auto cursor-pointer"
        >
          Завершить
        </div>
      </div>

      <LoadingFull :loading="loading" />
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import LoadingFull from "@/components/LoadingFull";

import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      form: {
        order_id: null,
        order_uuid: "",
        order_number: "",
        phone: "",
        order_type: null,
        mark: null,
        comment: "",
        device: "",
      },
      marks: [],
      errorMessage: false,
      showPage: true,
      loading: false,
      // baseUrl: "https://dev-nps-backend.texnomart.uz",
      baseUrl: "https://nps-admin.texnomart.uz",

      // error
      markError: false,
      commentError: false,
    };
  },
  components: {
    Header,
    LoadingFull,
  },
  mounted() {
    let i = 1;

    while (i <= 10) {
      this.marks.push({
        id: i,
        name: i,
      });
      i++;
    }

    // let data = this.$route.path.split("&");
    // let data2 = this.$route.path.split("?");
    // let data2_1 = data2[1].split("&");

    // let order_id = Number(data2_1[0].split("=")[1]);

    // let order_uuid = data[1].split("=")[1];
    // let order_uuid2 = order_uuid.replace(/['"«»]/g, ""); // данные без кавычек
    // console.log(order_uuid2);

    // let order_number = data[2].split("=")[1];
    // let phone = data[3].split("=")[1];
    // let order_type = Number(data[4].split("=")[1]);

    // modal
    this.form.order_id = this.$route.query.order_id;
    this.form.order_uuid = this.$route.query.order_uuid;
    this.form.order_number = this.$route.query.order_number;
    this.form.phone = this.$route.query.phone;
    this.form.order_type = this.$route.query.order_type;

    if (
      /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini|iPad|iPod/i.test(
        navigator.userAgent
      )
    ) {
      this.form.device = "mobile";
    } else {
      this.form.device = "PC";
    }

    // console.log(this.form.device);
    // console.log(window.navigator.userAgent);
  },

  methods: {
    changeTab(num) {
      this.form.mark = num;
      this.markError = false;
    },
    changeComment() {
      this.commentError = false;
    },

    sendСhoice() {
      this.loading = true;

      if (this.form.mark == null) {
        this.markError = true;
      }
      if (this.form.comment == "") {
        this.commentError = true;
      }
      axios
        .post(this.baseUrl + `/api/order/add-comment`, this.form)
        .then(async (res) => {
          await window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });

          if (res.data.success) {
            this.showPage = false;
          }
          this.loading = false;
        });
    },
    finishNps() {
      window.location.replace("https://texnomart.uz/kr");
    },
  },
};
</script>

<style lang="scss">
.form-input {
  padding-top: 14px;
  box-shadow: 0 0 0 1px #d5d8df;
  background-color: transparent;
  z-index: 2;
  position: relative;

  &.input-error {
    border: 2px solid #ff1616;
  }
  &:focus {
    box-shadow: 0 0 0 2px #fbc100;
    border: 0;
  }
  &:focus ~ .form-text {
    top: 8px;
    font-size: 12px;
    line-height: 16px;
  }
  &:focus ~ .form-error {
    display: none;
  }
  &:not(:placeholder-shown) ~ .form-text {
    top: 8px;
    font-size: 12px;
    line-height: 16px;
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.form-text {
  position: absolute;
  top: 16px;
  left: 20px;
  transition: 0.2s;
  z-index: 1;
  color: #767676;
}
</style>
