<template>
  <div class="text-center mt-40">
    <img src="@/assets/img/error.svg" alt="error" class="m-auto mb-10" />
    <div class="text-3xl font-medium">Страница не найдена</div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
